
<template>
  <div class="container">
    <NavbarComponent id="navbar" />
    <div class="landing-page"> 
     
      <div class="feature-box2 min-h-screen bg-primary-focus">
  <div class="hero-content flex-col lg:flex-row-reverse">
    <div>
      
      <h1 class="text-5xl welcome-text text-accent " id="bemvindo">bem-vindo ao navve.studio</h1>
<div class="accordion">
  <div class="accordion-item">
    <h2 class="accordion-header text-black py-3 cursor-pointer" @click="isAccordionOpen = !isAccordionOpen" id="descubra">DESCUBRA MAIS</h2>
    <div class="accordion-panel" v-if="isAccordionOpen">
      <div style="display: flex; column-gap: 20px;">
        <div style="flex: 1; font-size: 1.1em; text-align: left; padding: 20px;">
          <p class="py-6 text-black">
            Bem-vindo ao navve.studio, o seu portal para uma revolução na transmissão de vídeo. Aqui, inovação e qualidade andam de mãos dadas, oferecendo a você uma experiência de transmissão de vídeo sem igual.
          </p><br/>
          <p class="py-6 text-black">
            Explore funcionalidades inovadoras que transformam a maneira como você interage com o vídeo. Desde videochamadas de alta qualidade com baixa latência até a capacidade de transmitir arquivos de vídeo em tempo real, temos algo para todos.
          </p>
        </div>
        <div style="flex: 1; font-size: 1.1em; text-align: left; padding: 20px;">
          <p class="py-6 text-black">
            Se você é um profissional de produção em busca de soluções integradas de comunicação ou está simplesmente procurando formas de enviar sinais únicos de câmera e tela, encontrou o lugar certo. O navve.studio é equipado com as ferramentas certas para atender às suas necessidades.
          </p><br/>
          <p class="py-6 text-black">
            Além disso, estamos sempre à frente quando se trata de inovação. Com nossos recursos experimentais, você está sempre um passo à frente no mundo da transmissão de vídeo. Prepare-se para descobrir uma nova era da comunicação visual com o navve.studio.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
</div>
</div>

    <br/>

<br/><br/>
      
      <div class="features-grid">
   
        <div title="Experiência de Transmissão Superior com Whip" class="feature-box bg-neutral" v-if="showWhipConect" @click="$router.push('/whip-gen')">
          <span class="feature-icon">🚀</span>
          <span class="feature-text">Whip Enviar camera </span>
          <p class="feature-description">Envia sinais únicos de câmera e tela. Qualidade superior.</p>
        </div>
        
             <div title="Transmissão Única e Fácil de Câmera e Tela" class="feature-box bg-neutral"  @click="openExternalLink2('/engine/?push')">
        <span class="feature-icon">📷</span>
        <span class="feature-text">Enviar camera</span>
         <p class="feature-description">Envia sinais únicos de câmera e tela.</p>
        
      </div>
        
          <div title="Envie seu sinal para seu sistema de transmissão. OBS ou vmix." class="feature-box bg-neutral" @click="$router.push('/diretor2')">
        <span class="feature-icon">🎬</span>
        <span class="feature-text">Diretor</span>
        <p class="feature-description">Controle completo da video chamada.</p>
       
      </div>
      <div title="Conexões Fluidas com Vídeo Chamadas de Alta Qualidade. Ate 30 Usuarios" class="feature-box bg-neutral" v-if="showVideoChamada" @click="$router.push('/videochamada')">
          <span class="feature-icon">🎥</span>
          <span class="feature-text">Video Chamadas</span>
          <p class="feature-description">Alta qualidade com baixa<br> latência.</p>
        </div>

          <div title="Guia Passo a Passo para Dominar o Whip" class="feature-box bg-neutral"  @click="openExternalLink3('https://docs.google.com/document/d/e/2PACX-1vTTiwhzlI256KtXGhpLtSR_jx02nyRIteyDmqtYpHcLIkxfKDsV1x54zPl8jxvTYWg8pp_lieLHq2ff/pub')">
        <span class="feature-icon">⚙️</span>
        <span class="feature-text">Manual Whip</span>
         <p class="feature-description">Como funciona? Use esse guia. </p>
        
      </div>
         
      <div title="Transmissão de Vídeo Whip Simplificada com OBS Studio" class="feature-box bg-neutral" @click="$router.push('/whip')">
    <span class="feature-icon">📣</span>
    <span class="feature-text">Send Whip - OBS STUDIO </span>
    <p class="feature-description">Envie sinal de video whip direto pelo OBS STUDIO 30+</p>
</div>

     
     
         
        <div title="Comunicação Integrada de Voz e Vídeo para Produções Profissionais" class="feature-box bg-neutral" v-if="showInterCom" @click="$router.push('/intercom')">
          <span class="feature-icon">📞</span>
          <span class="feature-text">InterCom</span>
          <p class="feature-description">Comunicacao de voz e retorno de video para sua produção.</p>
        
        </div>
       
   
      <div title="Explore Novas Fronteiras com Stream de Arquivos de Vídeo" class="feature-box bg-neutral"  @click="$router.push('/engine/?push&fs')">
        <span class="feature-icon">▶️</span>
        <span class="feature-text">Arquivo para stream</span>
         <p class="feature-description">Envie um arquivo de video. *experimental </p>
        
      </div>
      <div class="feature-box" v-if="OffDisplay" @click="$router.push('/predef')">
        <span class="feature-icon">📅</span>
        <span class="feature-text">Sala Predefinida</span>
      </div>
      <div class="feature-box" v-if="OffDisplay" @click="$router.push('/playout')">
        <span class="feature-icon">▶️</span>
        <span class="feature-text">Player</span>
      </div>
      <div class="feature-box" v-if="OffDisplay" @click="$router.push('/blau')">
        <span class="feature-icon">🔍</span>
        <span class="feature-text">Blau</span>
      </div>
      <div class="feature-box" v-if="OffDisplay" @click="$router.push('/mixer')">
        <span class="feature-icon">🎶</span>
        <span class="feature-text">Mixer</span>
      </div>
      <div class="feature-box" v-if="OffDisplay" @click="$router.push('/viewlink')">
        <span class="feature-icon">🔗</span>
        <span class="feature-text">ViewLink</span>
      </div>
    </div>
  </div>
   </div>
   

</template>

<script>
import NavbarComponent from '@/components/NavbarComponent.vue';


export default {
  name: 'LandingPage',
  components: {
    NavbarComponent
  },
  data() {
    return {
      isAccordionOpen: false,
      showVideoChamada: true,
      showWhipConect: true,
      showInterCom: true,
      showOffDisplay: false,
    }
  },
    methods: {
    openExternalLink(url) {
      window.open(url, '_self');
    },
    openExternalLink2(url) {
      window.open(url, '_self');
    },
      openExternalLink3(url) {
      window.open(url, '_blank');
    },
     showComingSoonAlert() {
      alert("Em breve");
    }
   
  }
}
</script>
<style scoped>

  #bemvindo {
    font-weight: 900;
    font-variant: small-caps;
  }

  #descubra {
    font-weight: 200;
    text-decoration: underline;
  }
  .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 60px; /* considering the navbar's height */
}

#navbar {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
}

.landing-page {
  max-width: 1200px; /* max-width for desktop centralization */
  width: 100%;
  text-align: center;
  padding: 20px;
  color: #e5e7eb;
}

.welcome-text {
  font-size: 2rem;
  margin-bottom: 20px;
}

.brief-description {
  margin-bottom: 40px;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.feature-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
 
  color: rgb(255, 255, 255);
  border-radius: 15px;
  cursor: pointer;
  transition: all 0.3s;
  position: relative;
  height: 200px; /* to make it square */
}
.feature-box2{
border-radius: 20px;
}

.feature-box:hover {
  background-color: #e5e7eb;
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: #000;
}

.feature-icon {
  font-size: 2rem;
  margin-bottom: 10px;
}

.feature-text {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.feature-description {
  font-size: 0.9rem;
  position: absolute;
  bottom: 10px;
}
</style>
