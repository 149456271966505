<template><div  style=" background-color: black !important;">
  <div class="iframe-container" style=" background-color: black !important;">
    <div class="iframe-left">
      <iframe
        :src="iframeSrc"
        allow="autoplay;camera;microphone;fullscreen;picture-in-picture;display-capture;midi;geolocation;"
      ></iframe>
    </div>
    <p class="url-display">{{ iframeSrc }}</p>
  </div></div>
</template>
<script>
export default {
  data() {
    return {
      iframeSrc: "",
    };
  },
  created() {
    this.setIframeSrc();
  },
  methods: {
    setIframeSrc() {
      let queryString = window.location.search;
      if (queryString) {
        // Se o queryString já contiver um ?, nós apenas acrescentamos os parâmetros adicionais
        // Se não, nós iniciamos os parâmetros após o ?
       
        this.iframeSrc = `https://navve.studio/alpha/index.html${queryString}&videocontrols&color=FFFF0`;
      } else {
        this.iframeSrc = `https://navve.studio/alpha/?&videocontrols`;
      }

      const newPath = window.location.pathname + window.location.search;
      if (window.location.pathname + window.location.search !== newPath) {
        history.pushState({}, null, newPath);
      }
    },
  },
};
</script>

<style scoped>
body {
  background-color: black !important;
}
.iframe-container {
  display: flex;
  flex-direction: column;
  height: 100vh !important;
  width: 100vw;
  background-color: black !important;
}

.iframe-left {
  flex: 1;
  background: url('') center center no-repeat !important;
  background-size: fit !important; 
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.url-display {
  padding: 10px;
  background-color: #333;
  color: #fff;
  font-size: 16px;
  word-wrap: break-word;
  display: none !important;
}
</style>
