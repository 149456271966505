<template>
  <div class="dark" style="background-color:black;">
    <NavbarComponent />
    <div class="background-image"></div>

    <div
      id="card"
      class="flex items-center justify-center min-h-screen px-4"
    >
      <div class="card-container centered">
        <form
          style="max-width: 400px"
          class="card w-full max-w-lg bg-primary text-primary-content rounded-2xl shadow-xl"
          @submit.prevent="generateUrls"
        >
          <div class="card-body text-justify">
            <h2 class="card-title text-3xl font-semibold mb-6">
              Insira o ID de Envio
            </h2>
            <div class="mb-4">
              <label
                class="block text-gray-500 font-bold mb-1"
                for="push-id"
              >
              Pode ser qualquer palavra, frase ou número sem caracteres especiais ou espaços.
              </label>
              <input style="background-color: black !important;"
                class="input input-bordered text-cyan-400 bg-black input-success w-full max-w-xs"
                id="push-id"
                type="text"
                v-model="pushId"
                required
                maxlength="15"
              />
            </div>
            <button
              class="btn btn-outline btn-accent rounded-md hover:bg-opacity-80 focus:outline-none focus:ring focus:ring-primary-500 ml-2"
              type="submit"
            >
              Gerar URLs
            </button>
            <div v-if="urlsGenerated">
              <div class="mb-4">
    <label class="block text-gray-500 font-bold mb-1">URL de Envio</label>
    <input style="background-color: black !important;" class="input input-bordered w-full" type="text" v-model="sendUrl" readonly />
    <button id="copia"
              class="btn btn-outline btn-accent rounded-md hover:bg-opacity-80 focus:outline-none focus:ring focus:ring-primary-500 ml-2" @click="copyToClipboard('send')">📋</button>
    <span  style="color:red; " class="tooltiptext"> &nbsp;&nbsp;{{ tooltipSend }}</span>
  </div>
  <div class="mb-4">
    <label class="block text-gray-500 font-bold mb-1">URL de Saída de Vídeo</label>
    <input style="background-color: black !important;" class="input input-bordered w-full" type="text" v-model="videoOutputUrl" readonly />
    <button
    id="copia"
              class="btn btn-outline btn-accent rounded-md hover:bg-opacity-80 focus:outline-none focus:ring focus:ring-primary-500 ml-2" @click="copyToClipboard('videoOutput')">📋</button>
    <span  style="color:red; " class="tooltiptext">&nbsp;&nbsp;{{ tooltipVideoOutput }}</span>
  </div>
  <div class="mb-4">
    <label class="block text-gray-500 font-bold mb-1">Envio WHIP (Alta Qualidade)</label>
    <input style="background-color: black !important;" class="input input-bordered w-full" type="text" v-model="whipUrl" readonly />
    <button 
    id="copia"
              class="btn btn-outline btn-accent rounded-md hover:bg-opacity-80 focus:outline-none focus:ring focus:ring-primary-500 ml-2" @click="copyToClipboard('whip')">📋</button>
    <span style="color:red; " class="tooltiptext ">&nbsp;&nbsp;{{ tooltipWhip }}</span>
  </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarComponent from "../components/NavbarComponent.vue";

export default {
  components: {
    NavbarComponent,
  },
  data() {
    return {
      tooltipSend: '',
      tooltipVideoOutput: '',
      tooltipWhip: '',
      pushId: "",
      sendUrl: "",
      videoOutputUrl: "",
      whipUrl: "",
      urlsGenerated: false,
    };
  },
methods: {
  copyToClipboard(urlType) {
    let textToCopy = '';
    if (urlType === 'send') {
      textToCopy = this.sendUrl;
      this.tooltipSend = 'Link Copiado!';
    } else if (urlType === 'videoOutput') {
      textToCopy = this.videoOutputUrl;
      this.tooltipVideoOutput = 'Link Copiado!';
    } else if (urlType === 'whip') {
      textToCopy = this.whipUrl;
      this.tooltipWhip = 'Link Copiado!';
    }
    
    navigator.clipboard.writeText(textToCopy).then(() => {
      setTimeout(() => {
        this.clearTooltip(urlType);
      }, 2000);
    });
  },

  showTooltip(urlType, textToCopy) {
    if (urlType === 'send') {
      this.tooltipSend = 'Link Copiado!';
    } else if (urlType === 'videoOutput') {
      this.tooltipVideoOutput = 'Link Copiado!';
    } else if (urlType === 'whip') {
      this.tooltipWhip = 'Link Copiado!';
    }

    navigator.clipboard.writeText(textToCopy).then(() => {
      setTimeout(() => {
        this.clearTooltip(urlType);
      }, 2000);
    });
  },

  clearTooltip(urlType) {
    if (urlType === 'send') {
      this.tooltipSend = '';
    } else if (urlType === 'videoOutput') {
      this.tooltipVideoOutput = '';
    } else if (urlType === 'whip') {
      this.tooltipWhip = '';
    }
  },
    generateUrls() {
      const randomNum = Math.floor(Math.random() * 1000);
      this.sendUrl = `https://navve.studio/engine/?push=${this.pushId}&cbr&autogain=0&stereo=1&denoise=0`;
      this.videoOutputUrl = `https://navve.studio/engine/?view=${this.pushId}&codec=h264`;
      this.whipUrl = `https://navve.studio/engine/?push=${this.pushId}&whippush=https%3A%2F%2Fwhip.navve.studio&whippushtoken=${this.pushId}${randomNum}&whipoutcodec=h264&cbr&autogain=0&stereo=1&denoise=0`;
      this.urlsGenerated = true;
    },
    
  },
};
</script>


<style scoped>
body {
  margin: 0;
  
}
.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("");
  background-size: cover;
  background-position: center;
  opacity: 0.3;
  z-index: -1;
}
input {
  width: 100%;
}
#copia{
  

}
.centered {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.card-container {
  align-content: space-around;
  margin-top: 25vh;
  min-width: 350px;
}
.fixed-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}
.iframe-container {
  display: flex;
  flex-direction: row;
  height: calc(100vh);
  width: 100vw;
  padding-top: 0px;

 
}
.video {
 
  width: 100vw !important;

 
}

.iframe-left {
  flex: 1;
   background: url('https://i.pinimg.com/originals/58/1f/f4/581ff41e6ac2bbaa3e08646cf6be2798.gif') center center no-repeat !important;
  background-size: fit !important; 
}
  

iframe {
  width: 100%;
  height: 100%;
  border: none;
  

}

@media (max-width: 640px) {
  .centered {
    top: 40%;
  }

  .card-container {
    margin-top: 8vh;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  
}
.tooltiptext
.tooltip  {
  visibility: hidden;
  width: 120px;
  background-color: black !important;
  color: #fff;
  text-align: center;
  border-radius: 6px !important;
  padding: 5px 0;
  position: absolute !important;
  z-index: 1 !important;
  bottom: 125% !important; /* Posição do tooltip */
  left: 50% !important;
  margin-left: -60px !important; /* Alinhar o tooltip */
  opacity: 0 !important;
  transition: opacity 0.3s !important;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

</style>